<template>
  <div class="container p-4 mb-4">
    <b-input-group class="mtk-shadow bg-white">
      <b-input-group-prepend>
        <b-form-datepicker v-model="value" :min="min" :max="max" locale="de"></b-form-datepicker>
      </b-input-group-prepend>
      <b-form-select v-model="selected_time" :options="options_time"></b-form-select>
      <b-form-select v-model="selected_cat" :options="options_cat"></b-form-select>
      <b-form-select v-model="selected_meal" :options="options_meal"></b-form-select>
      <b-input-group-append>
        <b-form-select v-model="selected_location" :options="options_location"></b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-container class="my-4 py-2 mtk-shadow bg-white">
      <Plotly :data="data" :layout="layout" :display-mode-bar="true" :displaylogo="false"></Plotly>
      <b-button class="mt-1" size="sm" @click="refreshChart">{{ $t('btnOptions.refresh') }}</b-button>
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-white">
      <div class="overflow-auto">
        <h5>{{ $t('forecastSection.weeklyMenu') }}</h5>
        <p class="mt-3">{{ $t('pagination.page') }} {{ currentPage }}</p>
        <b-table
          id="my-table"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          small
        ></b-table>
        <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly';
import axios from 'axios';

export default {
  components: { Plotly },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'de', text: 'German (de)' },
      ],
      labels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren',
        },
      },
      value: '',
      min: '2018-01-01',
      max: maxDate,
      data: [],
      layout: {},
      perPage: 3,
      currentPage: 1,
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
      ],
      selected_time: null,
      options_time: [
        { value: null, text: 'Select time range' },
        { value: 'W', text: 'Weekly' },
        { value: 'M', text: 'Monthly' },
      ],
      selected_cat: null,
      options_cat: [
        { value: null, text: 'Select category' },
        { value: 1, text: 'Cat 1' },
        { value: 2, text: 'Cat 2' },
        { value: 3, text: 'Cat 3' },
      ],
      selected_meal: null,
      options_meal: [
        { value: null, text: 'Alle' },
        { value: 1, text: 'Menü 1' },
        { value: 2, text: 'Menü 2' },
        { value: 3, text: 'Menü 3' },
      ],
      selected_location: null,
      options_location: [
        { value: null, text: 'Select location' },
        { value: 1, text: 'Location 1' },
        { value: 2, text: 'Location 2' },
        { value: 3, text: 'Location 3' },
      ],
    };
  },
  methods: {
    refreshChart() {
      axios
        .put('http://localhost:5000/kmf/api/forecast/idx', {
          start_date: this.value,
          meal: this.selected_meal,
          aggr: this.selected_time,
          location: this.selected_location,
          category: this.selected_cat,
        })
        .then((response) => {
          this.data = response.data.data;
          this.layout = response.layout;
        });
    },
  },
  mounted() {
    this.refreshChart();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style>
.mh {
  height: 100px;
  /* max-height: 100px; */
}
</style>
